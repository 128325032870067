#header {
    background-color: var(--lavaranjinha-10, #FFEFEB);
    position: relative;
    min-height: 100vh;
}

#header h1 {
    color: #271D33;
    font-family: "Zain", sans-serif;
    font-size: calc(2rem + 2.75vw);
    text-shadow: 1px 1px #000;
}

#header .text-secondary {
    color: #544837 !important;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
}

#header .mb-6 {
    margin-bottom: 3rem;
}

#header .call-to-action {
    padding: .938rem 2.563rem;
    font-size: 1.25rem;
    border-radius: .25rem;
    color: #FFFEFE;
    background-color: var(--lavaranja, #FF5933);
    border-color: var(--lavaranja, #FF5933);
    font-weight: 600;
}