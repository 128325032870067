/* Team Section */
#team {
    background: var(--lavaranjinha-10, #fff);
}

#team h4 {
    margin: 5px 0;
}

#team .team-img {
    width: 240px;
}

#team .thumbnail {
    background: transparent;
    border: 0;
}

#team .thumbnail .caption {
    padding: 10px 0 0;
    color: #888;
}

.u-shape-inverse {
    position: relative;
    width: 100%;
    height: 100px;
    background: var(--lavaranjinha-10, #fff);
    clip-path: ellipse(60% 100% at 50% 100%);
}