#features {
    background: var(--lavaranjinha-10, #fff);
}

#features p {
    color: var(--body-text-color);
    font-size: 16px;
}

#features h2 {
    font-size: calc(1.5rem + 1.5vw);
}

#features h3 {
    font-size: 30px;
}

#features i.fa {
    font-size: 1.25rem;
    margin-bottom: 20px;
    margin-right: 20px;
    transition: all 0.5s;
    color: #fff;
    padding: 1rem;
    border-radius: 1rem;
    background: var(--foreground-gradient);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
}

#features .call-to-action {
    padding: 0.938rem 2.563rem;
    font-size: 1.25rem;
    border-radius: 0.25rem;
    color: #fffefe;
    background-color: var(--lavaranja, #ff5933);
    border-color: var(--lavaranja, #ff5933);
    font-weight: 600;
}

.u-shape {
    position: relative;
    width: 100%;
    height: 100px;
    background: var(--lavaranjinha-10, #fff);
    clip-path: ellipse(60% 100% at 50% 0%);
}