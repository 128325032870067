/* Navigation */
#menu {
    padding: 15px;
    transition: all 0.8s;
    margin-bottom: 0px !important;
}

#menu.navbar-default {
    backdrop-filter: blur(10px);
    /* Applies the blur effect */
    -webkit-backdrop-filter: blur(10px);
    /* Safari support */
    border-color: rgba(231, 231, 231, 0);
}

#menu a.navbar-brand {
    font-family: "Zain", sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: var(--lavaranja);
    display: flex;
    align-items: center;
    /* text-transform: uppercase; */

    img {
        float: left;
        height: 24px;
        margin-right: 4px;
    }
}

#menu.navbar-default .navbar-nav>li>a {
    font-family: "Open Sans", sans-serif;
    color: var(--title-text-color, #000);
    font-size: 15px;
    font-weight: 400;
    padding: 8px;
}

#menu.navbar-default .navbar-nav>li>a:after,
#menu.navbar-default .dropdown li p:after {
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 2px;
    background: var(--background-gradient);
    content: "";
    transition: width 0.2s;
}

#menu.navbar-default .dropdown li {
    position: relative;
}

#menu.navbar-default .navbar-nav>li>a:hover:after,
#menu.navbar-default .dropdown li p:hover:after {
    width: 100%;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    background-color: transparent;
}

.navbar-default .navbar-nav>.active>a:after,
.navbar-default .navbar-nav>.active>a:hover:after,
.navbar-default .navbar-nav>.active>a:focus:after {
    display: block !important;
    position: absolute !important;
    left: 0 !important;
    bottom: -1px !important;
    width: 100% !important;
    height: 2px !important;
    background: var(--background-gradient) !important;
    content: "" !important;
    transition: width 0.2s !important;
}

.navbar-toggle {
    border-radius: 0;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #fff;
    border-color: var(--lavaranja);
}

.navbar-default .navbar-toggle:hover>.icon-bar {
    background-color: var(--lavaranjinha);
}


#menu .call-to-action {
    padding: .5rem 1rem;
    color: #FFFEFE;
    background-color: var(--lavaranja, #FF5933);
    border-color: var(--lavaranja, #FF5933);
}

#menu .dropdown i {
    font-size: 1.5rem;
}

#menu .dropdown img {
    max-height: 32px;
}


.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: auto;
    right: 0;
    margin-top: var(--bs-dropdown-spacer);
}