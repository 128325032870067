/* Contact Section */
#contact {
    background: var(--background-gradient);
    color: rgba(255, 255, 255, 0.75);
}

#contact h2 {
    color: var(--lavaranjinha-10);
}

#contact .btn-custom {
    color: var(--accent-high);
    background: var(--lavaranjinha-10);
    border: 2px solid var(--lavaranjinha-10);
}

#contact .btn-custom:hover {
    background: transparent;
    color: var(--body-text-color);
}

#contact .form-control:focus {
    border-color: var(--body-text-color);
    outline: 0;
    -webkit-box-shadow: transparent;
    box-shadow: transparent;
}

#contact .contact-item span {
    color: var(--lavaranjinha-10);
    display: block;
}

#contact .contact-item a {
    text-decoration: none;
    color: inherit;
}

#contact .toast-body {
    color: var(--body-text-color);
}

#contact .toast img {
    width: 20px;
}

#contact .toast strong {
    font-family: "Zain","Open Sans", sans-serif;
    color: var(--lavaranja);
    font-size: 20px;
}